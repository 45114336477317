/* You can add global styles to this file, and also import other style files */

@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

.loader {
    border: 7px solid #f3f3f3;
    border-top: 7px solid dimgrey;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 0.5s linear infinite;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20%;
 
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.filter-box {
  display: flex;
  padding: 15px 20px 3px;
  justify-content: flex-end;
  align-items: center;
}

.btn-outline-secondary {
  margin: 2px 0;
  min-width: 75px;
}
